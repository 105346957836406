$primary-color: rgb(54, 56, 57);

.client__listing__cont {
  margin: 3em 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.client__listing__content__cont {
  width: 100%;
  display: flex;
  padding: 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 440px) {
    padding: 2em 1em;
  }
}

.client__listing__header {
  font-size: 30px;
  margin-bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $primary-color;
  color: whitesmoke;
  border-bottom: 1px solid red;
}

.client__listing__client_cont {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
  width: 100%;
  line-height: 30px;

  @media (max-width: 440px) {
    line-height: 20px;
    flex-direction: column;
  }
}

.client__listing {
  display: flex;
  // color: black;
  color: rgb(228, 230, 235);
  font-size: 20px;
  text-transform: uppercase;

  > span {
    margin-right: 0.5em;
  }

  @media (max-width: 440px) {
    font-size: 12px;
  }

  > p {
    &:hover {
      text-decoration: underline;
    }
  }
}
