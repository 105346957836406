.contact-us-details-map_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100vw;
  }
}
.contact-us--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75vw;
  height: 50vh;

  @media (max-width: 1024px) {
    width: 100vw;
    height: 60vh;
  }
}

.contact-us__details__cont {
  width: 40%;

  @media (max-width: 1024px) {
    display: none;
  }
}

.contact-us__maps__cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.iframe--gmaps {
  border: none;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    width: 90vw;
    height: 60vh;
  }
}
