$primary-color: rgb(54, 56, 57);
.image__slider__container {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;

  @media (max-width: 440px) {
    height: 40vh;
  }
}

.slider__image {
  position: absolute;
  top: -250px;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(-60%);
  // transition: all 2s ease;
  transition: opacity 1s linear;

  @media (max-width: 440px) {
    top: 0;
  }

  &--top {
    @extend .slider__image;
    top: 0;
  }
}

@media screen and (min-width: 912px) {
  .slider__image img {
    width: 100%;
  }
}

.slider__image img {
  width: 100%;
  height: 100%;

  @media (max-width: 440px) {
    height: 40vh;
  }
}

.slider__image--current {
  opacity: 1;
  transform: translateX(0);
}

.slider__image__content {
  position: absolute;
  top: 23rem;
  right: 5rem;
  opacity: 0;
  width: 30vw;
  color: #fff;
  padding: 3rem;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
  animation: slide-up 1s ease 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;

  @media (max-width: 440px) {
    animation: none;
  }
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 0rem;
  }

  100% {
    visibility: visible;
    top: 65vh;
  }
}

.slider__image__content > * {
  color: #fff;
  // margin-bottom: 1rem;
}

.slider__image--current .slider__image__content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.slider__arrow {
  border: 2px solid white;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 50%;
}

.slider__arrow:hover {
  background-color: #fff;
  color: #777;
}

.slider__arrow-right {
  right: 1.5rem;
}

.slider__arrow-left {
  left: 1.5rem;
}

hr {
  height: 2px;
  background: white;
  width: 50%;
}

/* media queries */

@media (max-width: 280px) {
  .slider__image__content {
    width: 50%;
    padding-bottom: 0;
  }

  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 0rem;
    }

    100% {
      visibility: visible;
      top: 40%;
      right: 0;
      font-size: 0.75rem;
    }
  }
}

@media screen and (max-width: 375px) {
  .slider__image__content {
    width: 50%;
    padding-bottom: 0;
  }

  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 0rem;
    }

    100% {
      visibility: visible;
      top: 40%;
    }
  }
}

@media (max-width: 540px) {
  .slider__image__content {
    width: 50%;
    padding-bottom: 0;
  }

  .slider__arrow {
    height: 1rem;
    width: 1rem;
  }
}

@media (max-width: 1024px) {
  .slider__image__content {
    padding-bottom: 0;
  }
}

.slider__button {
  height: 2.5em;
  width: 8em;
  background: white;
  color: $primary-color;
  border-radius: 0.5em;
  margin-top: 2em;
  cursor: pointer;
}
