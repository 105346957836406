.service__details__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
}

.service__details__header {
  text-align: center;
}

.service__details__details__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: whitesmoke;
  background: rgb(36, 37, 38);
  margin-top: 2em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  width: 80vw;
}

.service__details__intro__cont {
  display: flex;
  margin: 2em;
}

.service__details__intro {
  font-size: 24px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.service__details__items__cont {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  width: 60vw;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
}

.service__details__items__para__cont {
  display: flex;
  margin-bottom: 0.5em;

  > span {
    margin-right: 0.5em;
  }
}

.service__details__items__para {
  display: flex;
  justify-content: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  font-size: 24px;
  padding-right: 2em;

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 14px;
  }
}

.service__details__items__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  overflow: hidden;

  > img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 200px;
  }
}
