$primary-color: rgb(54, 56, 57);

.mission__article__cont {
  // background: whitesmoke;
  height: 100%;
  // box-shadow: 0px 4px 5px red;

  &--vision {
    @extend .mission__article__cont;
    @media (max-width: 440px) {
      background-color: rgb(25, 25, 25);
      color: white;
    }
  }

  &--home {
    @extend .mission__article__cont;
  }
}

.mission__article__content__cont {
  padding: 1em 2.5em 0;

  &--home {
    @extend .mission__article__content__cont;
  }
}

.mission__article__header__cont {
  font-size: 30px;
  background: linear-gradient(to top, rgb(36, 37, 38), #434343);
  // border-radius: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: whitesmoke;
  border-bottom: 2px solid rgba(255, 0, 0);
  text-transform: uppercase;
  padding: 0.5em 0;

  &--vision {
    @extend .mission__article__header__cont;
    @media (max-width: 440px) {
      background: linear-gradient(to top, #f6f2f2, #d9d9d9);
      color: rgb(25, 25, 25);
    }
  }

  &--home {
    @extend .mission__article__header__cont;
    border-radius: 12px;
    border-bottom: 2px solid rgba(255, 0, 0, 0.8);
  }
}

.mission__article__header {
  margin-left: 0.3em;

  &--home {
    @extend .mission__article__header;
  }
}

.mission__article__content {
  padding: 1em;
  font-size: 20px;
  line-height: 24px;
  background: rgb(36, 37, 38);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  // color: white;

  &--clients {
    @extend .mission__article__content;
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
  }

  &--home {
    @extend .mission__article__content;
    background: none;
    color: rgb(62, 64, 66);
  }
}

.mission__article__content > p {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.mission__article__button__cont {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 1.5em 0;
}

.mission__article__button {
  padding: 0.5em 2em;
  border: 1px solid $primary-color;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  transition: 0.5s ease-in-out;
  margin: 2em 0;

  &:hover {
    color: whitesmoke;
    background: $primary-color;
    border: 1px solid red;
  }
}
