.nav__mobile__cont {
  //   background: gray;
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 3;
  display: none;

  @media (max-width: 440px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.nav__burger__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-right: 1em;
  transition: 0.1s ease-in-out;
  width: 60px;
  height: 60px;
  background: white;
  border: 1px solid gray;

  &--active {
    @extend .nav__burger__cont;
    background: black;
    border: 1px solid red;
    border-radius: 50%;
    // row-gap: 0;
  }

  &--scrolled {
    @extend .nav__burger__cont;
    width: 30px;
    height: 30px;
  }
}

.nav__burger__line {
  width: 40px;
  border-top: 5px solid black;
  transition: 0.4s ease-in-out;
  margin-bottom: 5px;

  &--scrolled {
    width: 20px;
    margin-bottom: 3px;
    border-top: 3px solid black;
  }

  &--line1 {
    @extend .nav__burger__line;
    &--active {
      @extend .nav__burger__line--line1;
      //   position: absolute;
      border-color: white;
      //   transform: rotate(45deg);
    }
  }

  &--line2 {
    @extend .nav__burger__line;

    &--active {
      @extend .nav__burger__line--line1;
      width: 30px;
      border-color: white;
      //   transform: rotate(-45deg);
    }
  }

  &--line3 {
    @extend .nav__burger__line;
    margin-bottom: 0;
    &--active {
      @extend .nav__burger__line--line1;
      width: 15px;
      border-color: white;
      //   transform: rotate(-45deg);
    }
  }
}

.nav__mobile__logo__cont {
  display: flex;
  justify-content: center;
  margin-top: 1em;

  > img {
    width: 55%;
  }
}

.nav__mobile__menu {
  height: 100vh;
  font-size: 24px;
  color: red;
  position: fixed;
  background: linear-gradient(to top, #000000, #434343);
  z-index: 2;
  transition: 0.2s ease-in-out;
  width: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--active {
    @extend .nav__mobile__menu;
    width: 70vw;
  }
}

.nav__mobile__links {
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
}

.nav__mobile__link {
  padding: 1em;
  color: white;
}
