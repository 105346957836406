h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Inria-Serif"; */
  text-transform: uppercase;
  letter-spacing: 5px;
}

body {
  /* font-family: "Inika"; */
  letter-spacing: 3px;
  /* background: linear-gradient(to bottom, #ece9e6, #ffffff); */
  background: rgb(24,25,26);
  color: rgb(228,230,235);
}

p {
  line-height: 30px;
}
