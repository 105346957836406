.about__page__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_page__article__cont {
  // display: flex;
  // justify-content: center;
  // column-gap: 2.5em;
  // flex-wrap: wrap;
  display: grid;
  grid-template-areas:
    "mission vision  "
    "mission vision  "
    "clients clients  ";
  // gap: 3em;
  width: 75vw;

  @media (max-width: 1024px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

.about_page__overview__cont {
  grid-area: overview;
}

.about_page__services__cont {
  grid-area: services;
}

.about_page__mission__cont {
  grid-area: mission;
}

.about_page__vision__cont {
  grid-area: vision;
}

.about_page__clients__cont {
  grid-area: clients;
}
