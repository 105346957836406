* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  text-transform: uppercase;
}
