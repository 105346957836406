.home__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__services__article {
  height: 69vh;
}

.home__article__overview {
  width: 50vw;
  margin: 2em 0;

  @media (max-width: 1024px) {
    width: 100vw;
  }
}

.home__article__cont {
  display: flex;
  justify-content: center;
  //   height: 50vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.65),
      rgba(255, 255, 255, 0.74)
    ),
    url("/assets/image/const2.jpg");
  // background-size: 100vw 50vh;
  background-position: 35% 25%;
  background-size: 100vw;
  background-repeat: no-repeat;
  //   margin: 2em 0;
  //   background: black;

  @media (max-width: 1024px) {
    background-size: cover;
  }
}

.home__article__featured {
  //   width: 70vw;
  // padding: 1em 2.5em 0;
  margin: 2em 0;
  // width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 1em 0;
    // align-items: flex-start;
  }
}

.home__article__featured__header__cont {
  font-size: 30px;
  background: linear-gradient(to top, rgb(36, 37, 38), #434343);
  color: whitesmoke;
  border-bottom: 2px solid rgba(255, 0, 0, 0.8);
  text-transform: uppercase;
  padding: 0.5em 1em;
  width: 80vw;
  display: flex;
  justify-content: flex-start;
  border-radius: 12px;
}

.home__article__cta__cont {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50vw;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.home__article__cta__content {
  margin: 1em 2em 0 2em;
}

.home__article__cta {
  padding: 1em 3em;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: 0.5s ease-in-out;
  letter-spacing: 2px;

  &:hover {
    // background: black;
    // color: white;
    scale: 1.1;
  }
}

.home__article__content__cont {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  width: 75vw;
  margin-top: 2em;

  @media (max-width: 1024px) {
    width: 80vw;
  }
}

.home__article__content__wrapper {
  display: flex;
  justify-content: center;
}

.home__article__content {
  display: flex;
  align-items: center;
  width: 75vw;
  margin: 1em 1em 0 0;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1em 0 0 0;
  }
}

.home__article__header__cont {
  margin-bottom: 1em;
  width: 50vw;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.home__article__header {
  letter-spacing: 3px;
  @media (max-width: 1024px) {
    margin-bottom: 0.5em;
  }
}

.home__article__image__cont {
  width: 45vw;

  @media (max-width: 1024px) {
    width: 100%;
  }

  > img {
    width: 100%;
    border-radius: 12px;
  }
}

.home__services__cat {
  display: flex;
  align-items: center;
  margin: 2em 0;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100vw;
  }
}

.home__services__content__cont {
  display: flex;
  margin-top: 1em;
  column-gap: 2em;

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1em;
  }
}

.home__services__cont {
  // border: 2px solid black;
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
  //   rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
  //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: 25.1vw;
  height: max-content;
  background: rgb(36, 37, 38);
  border: 1px solid rgb(62, 64, 66);
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 1024px) {
    width: 90vw;
  }
}

.home__services__content__header {
  margin: 1em 0 0 2em;
  font-weight: 700;
  font-size: 1.5em;
  //   background: black;
  background: linear-gradient(to top, rgb(36, 37, 38), #434343);
  border: 2px solid rgb(62, 64, 66);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: none;
  color: white;
  padding: 1em 0.5em;
}

.home__service__details {
  display: flex;
  column-gap: 5px;
}

.home__services__details__cont {
  margin: 1em 0;
  padding: 0 4em;
}

.home__services__details__detail__cont {
  line-height: 18px;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.home__services__cta {
  margin-top: 2em;
}

.home__services__button {
  padding: 1em 3em;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: 0.5s ease-in-out;
  letter-spacing: 2px;

  &:hover {
    // background: black;
    // color: white;
    scale: 1.1;
  }
}

.home__quote__cont {
  // background: rgba(25, 25, 25, 1);
  color: whitesmoke;
  // margin-top: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.5em;
  height: 15vh;
  // width: 50%;
}

.home__quote__header__cont {
  margin-top: 2em;
  text-align: center;
  width: 75vw;
}

.home__quote__header {
  text-transform: none;

  font-family: "Staatliches";
  //   font-style: italic;
  > span {
    font-family: "Staatliches";
  }
}

.home__quote__cta__cont {
  // margin-bottom: 2em;
}

.home__quote__cta {
  padding: 1em 3em;
  border-radius: 6px;
  border: 2px solid white;
  //   background: black;
  background-color: rgba(25, 25, 25, 1);
  cursor: pointer;
  color: white;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: 0.5s ease-in-out;
  letter-spacing: 2px;

  &:hover {
    background: white;
    color: black;
    scale: 1.1;
    border: 2px solid black;
  }
}

.home__download__cont {
  background-position: 35% 25%;
  height: 15vh;
  // width: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // column-gap: 2em;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.home__download__header_cont {
  @media (max-width: 1024px) {
    width: 90vw;
  }
}

.home__download__header {
  text-align: center;
  margin-top: 1em;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
}

.home__download__icon {
  width: 50px;
  transition: 0.5s ease-in-out;

  @media (max-width: 1024px) {
    width: 30px;
  }

  @media (max-width: 440px) {
    width: 40px;
  }

  &:hover {
    scale: 1.5;
  }
}

.home__download__cta__cont {
  display: flex;
  align-items: center;
}

.home__cta__cont {
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.4)
    ),
    url("/assets/image/consplan.jpg");
  background-size: 100vw;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 440px) {
    flex-direction: column;
  }
}
