.services__details__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services__details__image-slider__cont {
  display: flex;
  align-items: center;
  height: 75vh;
  overflow: hidden;

  width: 80vw;

  @media (max-width: 440px) {
    width: 100vw;
  }
}

.services__details__image-slider {
  display: flex;
  justify-content: center;
}
