$primary-color: rgb(54, 56, 57);

.footer__cont {
  // height: 235px;
  width: 100%;
  background: linear-gradient(to bottom, #000000, #434343);
  // background: black;
  // background-color: rgba(25, 25, 25, 1);
  // background-color: rgb(36, 37, 38);

  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  color: whitesmoke;
  border-top: 1px solid rgba(255, 0, 0, 0.7);
  box-shadow: 0 2px 5px red;

  @media (max-width: 440px) {
    height: auto;
  }
}

.footer__content__cont {
  display: flex;
  width: 80vw;
  justify-content: space-around;
  margin: 2em 0;

  @media (max-width: 440px) {
    flex-direction: column;
    margin: 2em;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100vw;
  }
}

.footer__det__cont {
  display: flex;
  // max-width: 19vw;

  @media (max-width: 440px) {
    width: 80vw;
    max-width: none;
  }
}

.footer__det__items {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.footer__det__address,
.footer__det__mobile,
.footer__det__email {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  width: fit-content;
}

.footer__det__icon__cont {
  margin-right: 1em;

  > img {
    width: 24px;
  }
}

.mapping__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.2em;
}

.footer__nav__cont {
  display: flex;
  flex-direction: column;
  max-width: 19vw;

  @media (max-width: 440px) {
    display: none;
  }
}

.footer__nav__header,
.footer__social__header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.footer__nav__link__items {
  display: flex;
  flex-direction: column;
  row-gap: 0.1em;
}

.footer__nav__link__item {
  color: whitesmoke;

  &:hover {
    color: red;
  }
}

.footer__arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 0.5em;

  &--right {
    transform: rotate(-45deg);
  }
}

.footer__social__cont {
  max-width: 19vw;

  @media (max-width: 440px) {
    width: 80vw;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
  }
}

.footer__social__icon__cont {
  width: 100%;
  > img {
    width: 40px;
  }
}

.footer__accreditted__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.footer__accreditted {
  display: flex;
  height: 70px;

  > img {
    width: 100%;
  }
}
