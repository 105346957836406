.contact-us__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us__form__cont {
  display: flex;
  flex-direction: column;
  width: 60vw;
  align-items: center;
  margin: 1em 0;

  @media (max-width: 1024px) {
    width: 80vw;
  }
}

.contact-us__form__items {
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    display: block;
  }
}

.contact-us__inputLabel__cont {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 1em;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 1.5em 0 0 0;
  }

  &--message {
    @extend .contact-us__inputLabel__cont;
    width: 100%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.contact-us__inputLabel__label {
  width: 100%;
  text-align: left;
}

.contact-us__input-text {
  height: 50px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 0 0.5em;

  &--message {
    @extend .contact-us__input-text;
    height: 20vh;
    padding: 1em 0.5em;
  }

  &--error {
    @extend .contact-us__input-text;
    border: 1px solid red;
  }
}

.contact-us__form__submit {
  //   width: 180px;
  margin: 2em 0;
  text-transform: uppercase;
  padding: 1em 3em;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: 0.5s ease-in-out;
  letter-spacing: 2px;

  &:hover {
    background: black;
    color: white;
    scale: 1.1;
  }

  @media (max-width: 1024px) {
    margin-top: 2em;
  }
}

.contact-us__inputLabel__error__cont {
  position: absolute;
  color: red;
}

.iframe--sending {
  height: 30vh;
  width: 50vw;
  border: none;
}

.contact-us__form__cta {
  border: 1px solid whitesmoke;
  background: whitesmoke;
  padding: 1em 3em;
  border-radius: 12px;
  transition: 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
    scale: 1.1;
  }
}
