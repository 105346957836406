.project__results__cont {
  display: flex;
  flex-direction: column;
  margin-top: 5em;
}

.projects__results {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.projects__results__header {
  width: 100%;
  text-align: center;

  // @media (max-width: 440px) {
  //   font-size: 16px;
  // }
}

.projects__results__result {
  margin: 2em 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 60vw;
}

.projects__result__item {
  margin: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  min-width: 350px;
  max-width: 350px;
  width: 350px;
  background: white;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.15);
  }

  @media (max-width: 440px) {
    min-width: 280px;
    max-width: 280px;
    width: 280px;
  }
}

.project__image__item {
  width: 100%;
  height: 20vh;
  display: flex;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.projects__description__tag {
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
}

.projects__results__service {
  background: black;
  width: fit-content;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: white;
  padding: 2px 10px;
  text-transform: uppercase;
  padding: 1em;
  margin: 0 1em;
}

.project__result__title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1.5em 1em;
}

.result__page__numbers__cont {
  display: flex;
  justify-content: center;
}

.page__number {
  cursor: pointer;
  padding: 1em;
  border: 1px solid red;
}

.arrow__page {
  position: relative;
  background: rgb(58, 59, 60);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  transition: 0.4s ease;

  &:hover {
    scale: 1.1;
    box-shadow: 0px 0px 1px 1px red;
  }

  & span {
    position: absolute;
    height: 15px;
    width: 15px;
    border-top: 4px solid white;
    border-left: 4px solid white;
    transform: rotate(0deg);
    left: 10px;
    top: 10px;
  }

  &--left {
    transform: rotate(-45deg);
    margin-right: 1em;
  }

  &--right {
    transform: rotate(135deg);
    margin-left: 1em;
  }
}

.pagination__cont_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
