$primary-color: rgb(54, 56, 57);

.navbar__cont {
  height: 90px;
  background: linear-gradient(to top, #000000, #434343);
  color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  border-bottom: 1px solid rgba(255, 0, 0, 0.7);
  box-shadow: 0 2px 5px red;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 1024px) {
    justify-content: center;
    height: 10vh;
  }
}

.navbar__logo__cont {
  width: 40vw;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: unset;
    overflow: hidden;
  }
}

.navbar__logo__cont > img {
  height: 78px;

  @media (max-width: 1024px) {
    height: 56px;
  }
}

.navbar__menu__cont {
  display: flex;
  justify-content: center;
  width: 60vw;

  @media (max-width: 1024px) {
    width: unset;
  }
}

.navbar__menu__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 9em;
  height: 100%;
  cursor: pointer;
  list-style: none;
  text-align: center;
  align-items: center;

  @media (max-width: 1024px) {
    min-width: unset;
  }
}

.navbar__link {
  color: whitesmoke;
  padding: 2.2em 1.5em;

  @media (max-width: 1024px) {
    padding: 1em;
  }

  &:hover {
    background: whitesmoke;
    color: $primary-color;
  }
  &--about {
    @extend .navbar__link;
    display: flex;
    justify-content: center;
  }
}

.navbar__menu_about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2.2em 0;
  min-width: 9em;

  &:hover {
    background: whitesmoke;
    color: $primary-color;
  }

  @media (max-width: 1024px) {
    padding: 1em;
    min-width: unset;
  }
}
