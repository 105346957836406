.project__detail__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project__detail__header__cont {
  text-align: center;
  margin-top: 2em;

  @media (max-width: 440px) {
    margin-top: 6em;
  }
}

.project__detail__slider__image {
  display: flex;
  align-items: center;
  margin: 2em 0;
  width: 75vw;
  height: 45vh;
  overflow: hidden;

  @media (max-width: 440px) {
    width: 100vw;
  }
}

.project__detail__desc__cont {
  display: flex;
  width: 75vw;
  flex-direction: column;

  @media (max-width: 440px) {
    align-items: center;
  }
}

.project__detail__desc__header__cont {
  margin: 0 0 1em 0;
  font-size: 24px;

  @media (max-width: 440px) {
    text-align: center;
  }
}

.project__detail__desc {
  font-size: 20px;
  line-height: 40px;
  width: 70vw;
  margin-left: 2em;
  margin-bottom: 2em;

  > p {
    font-weight: bold;
    margin-bottom: 1em;

    > span {
      font-weight: normal;
    }
  }
}
