.projects-gallery__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  @media (max-width: 440px) {
    width: 95%;
  }
}

.filter__header__cont {
  display: flex;
  align-items: center;
  height: 60px;
  background: rgb(36, 37, 38);
  border-bottom: 2px solid rgb(54, 55, 56);
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  @media (max-width: 440px) {
    height: 160px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 150px;
  }
}

.filter__items {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin: 1em;
  white-space: nowrap;

  @media (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;
    white-space: wrap;
    align-items: flex-start;
    row-gap: 0.5em;
  }
}

.filter__item {
  display: flex;
  align-items: center;

  & > span {
    margin-right: 1em;

    @media (max-width: 1024px) {
      margin-bottom: 0.5em;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.projects-gallery__results__cont {
  height: max-content;
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgb(36, 37, 38);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
