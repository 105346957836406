.modal {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.4);
}

.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.modal-exit {
  opacity: 0;
  transform: scale(0.4);
}

.modal-content {
  width: 70%;
  height: 70%;
  /* background-color: white; */
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
}

.modal-content > p {
  margin-top: 1em;
  width: 50%;
  text-align: center;
}

.close-btn {
  color: #413e3c;
  background-color: transparent;
  border: none;
  font-size: 35px;
  position: absolute;
  right: 32px;
  top: 16px;
  cursor: pointer;
}
