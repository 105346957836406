$primary-color: rgb(54, 56, 57);

.photo__gallery__cont {
  display: flex;
  justify-content: center;
  margin: 3em 0;
  width: 100%;
}

.photo__gallery__section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.photo__gallery__content__cont {
  width: 100%;
  display: flex;
  padding: 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.photo__gallery__header {
  // font-size: 30px;
  // margin-bottom: 1em;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // background: $primary-color;
  // color: whitesmoke;
  // border-bottom: 1px solid red;
  font-size: 30px;
  background: linear-gradient(to top, rgb(36, 37, 38), #434343);
  color: whitesmoke;
  border-bottom: 2px solid red;
  text-transform: uppercase;
  padding: 0.5em 1em;
  width: 80vw;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  border-radius: 12px;
}

.photo__gallery__desc {
  font-size: 24px;
  margin: 0 2em 2em;
  text-align: center;
}

.photo__gallery__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;
  // width: 75%;
  // background: $primary-color;

  background: linear-gradient(100deg, rgb(36, 37, 38), #434343);
  border-radius: 12px;
  padding: 6em 0 0 0;

  @media (max-width: 440px) {
    width: 100%;
  }
}

.photo__gallery__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 1.5em;
  font-size: 22px;
  color: whitesmoke;
  min-width: 350px;
  max-width: 350px;
  width: 350px;

  height: 280px;
  max-height: 280px;
  min-height: 280px;

  @media (max-width: 440px) {
    width: 200px;
    max-width: 200px;
    min-width: 200px;

    height: 200px;
    min-height: 200px;
    max-height: 200px;

    font-size: 14px;
  }
}

.gallery__profile__photo {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
  height: 140px;
  max-height: 140px;
  min-height: 140px;
  border-radius: 50%;
  // border: 1px solid red;
  box-shadow: rgba(255, 255, 255, 0.12) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
  @media (max-width: 440px) {
    width: 90px;
    min-width: 90px;
    max-width: 90px;

    height: 90px;
    min-height: 90px;
    max-height: 90px;
  }
}

.gallery__profile__name {
  margin-top: 1em;
  font-weight: 700;
  text-align: center;
}

.gallery__profile__position {
  font-size: 18px;
  text-align: center;

  @media (max-width: 440px) {
    font-size: 12px;
  }
}
