.gallery__service__cont {
  margin: 3em 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.gallery__service__header__cont {
}

.gallery__service__link {
  display: flex;
  justify-content: center;
  width: 100%;
  // overflow: hidden;
}

.gallery__service__services__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  height: 70vh;
  width: 80vw;

  @media (max-width: 440px) {
    flex-direction: column;
    row-gap: 1em;
    justify-content: flex-start;
    height: fit-content;
    width: 90vw;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    height: max-content;
  }
}

.gallery__service__services {
  margin: 0 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  height: 50vh;
  max-width: 20vw;
  min-width: 20vw;
  width: 20vw;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 12px;

  &:hover {
    transition: 0.4s ease-in;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    scale: 1.1;
  }

  @media (max-width: 440px) {
    min-width: 80vw;
    height: 40vh;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 1em;
    min-width: max-content;
  }
}

.gallery__service__image__cont {
  width: 100%;
  height: 35vh;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.gallery__service__title {
  margin: 2em 1em;
  font-weight: 700;
  color: black;
  text-align: center;
}
