.project__gallery__cont {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.projects__header__cont {
  display: flex;
  justify-content: center;
  margin-top: 3em;
}
