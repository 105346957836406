$primary-color: rgb(54, 56, 57);
.navbar__about__cont {
  background: whitesmoke;
  color: $primary-color;
  position: absolute;
  top: 70px;
  z-index: 1;
  font-size: 12px;
  min-width: 12.8em;
}

.navbar__about__items {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navbar__about__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  color: $primary-color;

  &:hover {
    color: whitesmoke;
    background: $primary-color;
  }
}
