.drop__down__cont {
  display: flex;
  align-items: center;
}

.drop__down__filter {
  margin-right: 1em;
}

.drop__down {
  padding: 0.5em;
  border-radius: 10px;

  @media (max-width: 440px) {
    // width: 210px;
    width: 55vw;
    max-width: max-content;
  }
}

.filter__clear__button {
  padding: 0.5em 1em;
  margin-right: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease;
  text-transform: capitalize;
  background: rgb(58, 59, 60);
  color: rgb(228, 230, 235);

  &:hover {
    scale: 1.1;
  }
}
